<template>
  <div class="soilScreen">
    <header class="header">
      <!-- <img class="logo-img" src="../../assets/image/column_nav/col_logo.png" /> -->
      <span class="header-title">
        {{ farmInfo.name ? farmInfo.name : "大旭智能数字农场管理平台" }}
      </span>
    </header>
    <div class="content-box">
      <div class="left-box">
        <div class="charts-box">
          <div class="charts-title">
            <div class="switch-box">
              <img
                class="warning"
                src="@/assets/image/analyse/warning.png"
                alt=""
                v-show="!soilHOnline"
              />
            </div>
            <div class="title-box">
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
              <span>{{ issoilHTitle ? "土壤湿度(采样)" : "土壤湿度" }} </span>
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
            </div>
            <div class="switch-box">
              <img
                class="switch"
                src="@/assets/image/analyse/switch.png"
                alt=""
                @click="changeData('soilH')"
              />
            </div>
          </div>
          <div ref="charts-humidity" class="charts-data-box humidity"></div>
          <div v-if="humidityShow" class="empty-box">暂无设备</div>
        </div>
        <div class="charts-box">
          <div class="charts-title">
            <div class="switch-box">
              <img
                class="warning"
                src="@/assets/image/analyse/warning.png"
                alt=""
                v-show="!soilTOnline"
              />
            </div>
            <div class="title-box">
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
              <span>土壤温度</span>
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
            </div>
            <div class="switch-box">
              <img
                class="switch"
                src="@/assets/image/analyse/switch.png"
                alt=""
              />
            </div>
          </div>
          <div
            ref="charts-temperature"
            class="charts-data-box temperature"
          ></div>
          <div v-if="temperatureShow" class="empty-box">暂无设备</div>
        </div>
        <div class="charts-box">
          <div class="charts-title">
            <div class="switch-box">
              <img
                class="warning"
                src="@/assets/image/analyse/warning.png"
                alt=""
                v-show="!soilCOnline"
              />
            </div>
            <div class="title-box">
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
              <span>土壤电导率</span>
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
            </div>
            <div class="switch-box">
              <img
                class="switch"
                src="@/assets/image/analyse/switch.png"
                alt=""
              />
            </div>
          </div>
          <div
            ref="charts-conductivity"
            class="charts-data-box conductivity"
          ></div>
          <div v-if="conductivityShow" class="empty-box">暂无设备</div>
        </div>
        <div class="charts-box">
          <div class="charts-title">
            <div class="switch-box">
              <img
                class="warning"
                src="@/assets/image/analyse/warning.png"
                alt=""
                v-show="!soilSOnline"
              />
            </div>
            <div class="title-box">
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
              <span>土壤盐分</span>
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
            </div>
            <div class="switch-box">
              <img
                class="switch"
                src="@/assets/image/analyse/switch.png"
                alt=""
              />
            </div>
          </div>
          <!-- <div ref="charts-salinity" class="charts-data-box salinity"></div> -->
          <div ref="charts-SoilS" class="charts-data-box SoilS"></div>
          <div v-if="SoilSShow" class="empty-box">暂无设备</div>
        </div>
      </div>
      <div class="conter-box">
        <div class="cycle-box">
          <div id="advert">
            <vue-particles
              class="ym-particles"
              color="#fff"
              :particleOpacity="0.4"
              :particlesNumber="30"
              shapeType="circle"
              :particleSize="4"
              linesColor="#fff"
              :linesWidth="1"
              :lineLinked="true"
              :lineOpacity="0.4"
              :linesDistance="30"
              :moveSpeed="2"
              :hoverEffect="true"
              hoverMode="grab"
              :clickEffect="true"
              clickMode="push"
            />
            <div class="bg_img"></div>
            <div class="bg1">
              <div
                class="out-box"
                :class="{
                  bg1_view: start,
                  four: info.counts.length == 4,
                  active: start,
                }"
                v-if="info.counts"
              >
                <div
                  class="cycle-item"
                  :class="item.img"
                  v-for="(item, index) in info.counts"
                  :key="index"
                >
                  <img
                    :src="
                      $imgOnlineUrl + '/huoqiu/analyse/' + item.img + '.png'
                    "
                    :class="'icon0' + item.img"
                  />
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </div>
            <div
              class="content"
              :class="{ content_active: end }"
              @click="getAnalysis"
            >
              <span>{{ info.new_img.title }}</span>
              <img
                :src="
                  $imgOnlineUrl + '/huoqiu/analyse/' + info.new_img.img + '.png'
                "
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="charts-box">
          <div class="charts-title">
            <div class="switch-box">
              <img
                class="warning"
                src="@/assets/image/analyse/warning.png"
                alt=""
                v-show="!vbatOnline"
              />
            </div>
            <div class="title-box">
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
              <span>电池电压</span>
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
            </div>
            <div class="switch-box">
              <img
                class="switch"
                src="@/assets/image/analyse/switch.png"
                alt=""
              />
            </div>
          </div>
          <div ref="charts-voltage" class="charts-data-box voltage"></div>
          <div v-if="voltageShow" class="empty-box">暂无设备</div>
        </div>
      </div>
      <div class="right-box">
        <div class="charts-box">
          <div class="charts-title">
            <div class="switch-box">
              <img
                class="warning"
                src="@/assets/image/analyse/warning.png"
                alt=""
                v-show="!dPHOnline"
              />
            </div>
            <div class="title-box">
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
              <span>干式PH值</span>
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
            </div>
            <div class="switch-box">
              <img
                class="switch"
                src="@/assets/image/analyse/switch.png"
                alt=""
              />
            </div>
          </div>
          <div ref="charts-ph" class="charts-data-box ph"></div>
          <div v-if="phShow" class="empty-box">暂无设备</div>
        </div>
        <div class="charts-box">
          <div class="charts-title">
            <div class="switch-box">
              <img
                class="warning"
                src="@/assets/image/analyse/warning.png"
                alt=""
                v-show="!sNOnline"
              />
            </div>
            <div class="title-box">
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
              <span>土壤磷传感器</span>
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
            </div>
            <div class="switch-box">
              <img
                class="switch"
                src="@/assets/image/analyse/switch.png"
                alt=""
              />
            </div>
          </div>
          <div ref="charts-phosphorus" class="charts-data-box phosphorus"></div>
          <div v-if="phosphorusShow" class="empty-box">暂无设备</div>
        </div>
        <div class="charts-box">
          <div class="charts-title">
            <div class="switch-box">
              <img
                class="warning"
                src="@/assets/image/analyse/warning.png"
                alt=""
                v-show="!sKOnline"
              />
            </div>
            <div class="title-box">
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
              <span>土壤钾传感器</span>
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
            </div>
            <div class="switch-box">
              <img
                class="switch"
                src="@/assets/image/analyse/switch.png"
                alt=""
              />
            </div>
          </div>
          <div ref="charts-potassium" class="charts-data-box potassium"></div>
          <div v-if="potassiumShow" class="empty-box">暂无设备</div>
        </div>
        <div class="charts-box">
          <div class="charts-title">
            <div class="switch-box">
              <img
                class="warning"
                src="@/assets/image/analyse/warning.png"
                alt=""
                v-show="!sNOnline"
              />
            </div>
            <div class="title-box">
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
              <span>土壤氮传感器</span>
              <img
                class="title_icon"
                src="@/assets/image/analyse/title_icon.png"
                alt=""
              />
            </div>
            <div class="switch-box">
              <img
                class="switch"
                src="@/assets/image/analyse/switch.png"
                alt=""
              />
            </div>
          </div>
          <div ref="charts-ammonia" class="charts-data-box ammonia"></div>
          <div v-if="ammoniaShow" class="empty-box">暂无设备</div>
        </div>
      </div>
    </div>
    <el-dialog
      class="analysis-pop"
      title=" "
      :visible.sync="isAnalysisPopShow"
      width="860px"
      :before-close="analysisPopClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="analysis-main">
        <div class="ware-title">AI数据分析</div>
        <div class="analysis-content">
          <div
            class="analysis-item"
            v-for="item in deviceDataList"
            :key="item.id"
          >
            <div class="analysis-show-box" v-if="item.isResult">
              <div class="analysis-item-title">{{ item.title }}</div>
              <div class="analysis-item-data">
                <span>当前数值：</span
                ><span>{{ item.last_data }}{{ item.data_unit }}</span>
              </div>
              <div class="analysis-item-data">
                <span>适宜数值： </span>
                <span v-if="item.alias === 'soilT'">
                  {{ info.new_img.minNum }}{{ item.data_unit }}~{{
                    info.new_img.maxNum
                  }}{{ item.data_unit }}
                </span>
                <span v-if="item.alias === 'sN'">
                  {{ info.new_img.sNNum }}{{ item.data_unit }}
                </span>
                <span v-if="item.alias === 'sP'">
                  {{ info.new_img.sPNum }}{{ item.data_unit }}
                </span>
                <span v-if="item.alias === 'sK'">
                  {{ info.new_img.sKNum }}{{ item.data_unit }}
                </span>
              </div>
              <div
                :class="[
                  'analysis-item-result',
                  item.isResult === 1
                    ? 'normal'
                    : item.isResult === 2
                    ? 'high'
                    : item.isResult === 3
                    ? 'low'
                    : '',
                ]"
              >
                {{
                  item.isResult === 1
                    ? "正常"
                    : item.isResult === 2
                    ? "偏高"
                    : item.isResult === 3
                    ? "偏低"
                    : "暂无"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllMarkDataApi,
  getEquipmentInfoApi,
  getEquipmentChildListApi,
  getEquipmentChildListInfoApi,
} from "@/request/api";
import moment from "moment";
import { mapState } from "vuex";
export default {
  data() {
    return {
      humidityShow: true,
      phShow: true,
      temperatureShow: true,
      SoilSShow: true,
      isAirHTitle: false,
      isAnalysisPopShow: false,
      issoilHTitle: false,

      soilHOnline: 0,
      soilTOnline: 0,
      soilSOnline: 0,
      soilCOnline: 0,
      dPHOnline: 0,
      sNOnline: 0,
      sPOnline: 0,
      sKOnline: 0,
      vbatOnline: 0,

      rainFOnline: 0,
      windSOnline: 0,
      windDOnline: 0,
      monthDay: "",
      airHNum: "",
      airTNum: "",
      airTWidth: 0,
      monthEnd_day: "",
      nowShowMonth: "",
      channel_id: "",
      table_id: "",
      deviceDataList: [],

      start: false,
      end: false,
      loading: false,
      info: {
        new_img: {},
        counts: [],
      },
      cycleList: [
        // {
        //   img: "chengshu",
        //   title: "成熟期",
        //   minNum: 8,
        //   maxNum: 20,
        //   date: [9, 10],
        //   isActive: false,
        // },
        {
          img: "guanjiangqi",
          title: "灌浆期",
          minNum: 13,
          maxNum: 23,
          sNNum: 23.9,
          sPNum: 2.25,
          sKNum: 8.2,
          // date: [8, 9],
          date: [9],
          isActive: false,
        },
        {
          img: "kaihuaqi",
          title: "开花期",
          minNum: 17,
          maxNum: 25,
          // date: [7, 8],
          sNNum: 23.9,
          sPNum: 2.25,
          sKNum: 8.2,
          date: [8],
          isActive: false,
        },
        {
          img: "fenhuaqi",
          title: "分化期",
          minNum: 16,
          maxNum: 23,
          date: [7],
          sNNum: 23.9,
          sPNum: 2.25,
          sKNum: 8.2,
          // date: [6, 7],
          isActive: false,
        },
        {
          img: "fennieqi",
          title: "分蘖期",
          minNum: 8,
          maxNum: 22,
          // date: [6, 7],
          sNNum: 23.9,
          sPNum: 2.25,
          sKNum: 8.2,
          date: [6],
          isActive: false,
        },
        {
          img: "miaoqi",
          title: "苗期",
          minNum: 6,
          maxNum: 22,
          sNNum: 23.9,
          sPNum: 2.25,
          sKNum: 8.2,
          date: [4, 5],
          isActive: false,
        },
      ],
      // 天气
      weatherObj: {},

      // 定时器
      setInterval: null,
      setInterval2: null,

      allDataList: {},
      cameraList: [],

      option: null,
      chartsPh: null,
      chartsVoltage: null,
      voltageShow: true,
      chartsSoilS: null,
      chartsAmmonia: null,
      ammoniaShow: true,
      chartsPotassium: null,
      potassiumShow: true,
      chartsHumidity: null,
      chartsPhosphorus: null,
      phosphorusShow: true,
      chartsConductivity: null,
      conductivityShow: true,
      chartsTemperature: null,
      chartsSalinity: null,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      farmInfo: (state) => state.user.farmInfo,
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.channel_id =
      this.$route.query.id || localStorage.getItem("equipmentId");
    this.table_id = this.$route.query.table;
    this.allFunction();
    this.updatePage();
    this.handleCycle();
  },
  mounted() {
    this.chartsSoilS = this.$echarts.init(this.$refs["charts-SoilS"]);
    this.chartsVoltage = this.$echarts.init(this.$refs["charts-voltage"]);
    this.chartsHumidity = this.$echarts.init(this.$refs["charts-humidity"]);
    this.chartsPh = this.$echarts.init(this.$refs["charts-ph"]);
    this.chartsPotassium = this.$echarts.init(this.$refs["charts-potassium"]);
    this.chartsPhosphorus = this.$echarts.init(this.$refs["charts-phosphorus"]);
    this.chartsAmmonia = this.$echarts.init(this.$refs["charts-ammonia"]);
    this.chartsConductivity = this.$echarts.init(
      this.$refs["charts-conductivity"]
    );
    this.chartsTemperature = this.$echarts.init(
      this.$refs["charts-temperature"]
    );
    this.chartsSalinity = this.$echarts.init(this.$refs["charts-salinity"]);
    this.getAllMarkDataApi();
  },

  beforeDestroy() {
    // 卸载定时器
    if (this.setInterval) {
      clearInterval(this.setInterval);
    }
    this.setInterval = null;
    if (this.setInterval2) {
      clearInterval(this.setInterval2);
    }
    this.setInterval2 = null;
  },
  methods: {
    changeData(type) {
      switch (type) {
        case "soilH":
          this.issoilHTitle = !this.issoilHTitle;

          break;

        default:
          break;
      }
    },
    analysisPopClose() {
      this.isAnalysisPopShow = false;
    },
    // 眼
    getAnalysis() {
      this.isAnalysisPopShow = true;
      // 土壤温度
      const soilTIndex = this.deviceDataList.findIndex(
        (item) => item.alias === "soilT"
      );
      if (soilTIndex != -1) {
        const last_data = this.deviceDataList[soilTIndex]?.last_data;
        if (
          last_data > this.info.new_img.minNum &&
          last_data < this.info.new_img.maxNum
        ) {
          this.deviceDataList[soilTIndex]["isResult"] = 1;
        } else if (last_data > this.info.new_img.maxNum) {
          this.deviceDataList[soilTIndex]["isResult"] = 2;
        } else {
          this.deviceDataList[soilTIndex]["isResult"] = 3;
        }
      }

      // 氮 sN
      const sNIndex = this.deviceDataList.findIndex(
        (item) => item.alias === "sN"
      );
      if (sNIndex != -1) {
        const sNLast_data = this.deviceDataList[sNIndex]?.last_data;
        if (sNLast_data > this.info.new_img.sNNum) {
          this.deviceDataList[sNIndex]["isResult"] = 2;
        } else if (sNLast_data < this.info.new_img.sNNum) {
          this.deviceDataList[sNIndex]["isResult"] = 3;
        } else {
          this.deviceDataList[sNIndex]["isResult"] = 1;
        }
      }

      // 磷 sP
      const sPIndex = this.deviceDataList.findIndex(
        (item) => item.alias === "sP"
      );
      if (sPIndex != -1) {
        const sPLast_data = this.deviceDataList[sPIndex]?.last_data;
        if (sPLast_data > this.info.new_img.sPNum) {
          this.deviceDataList[sPIndex]["isResult"] = 2;
        } else if (sPLast_data < this.info.new_img.sPNum) {
          this.deviceDataList[sPIndex]["isResult"] = 3;
        } else {
          this.deviceDataList[sPIndex]["isResult"] = 1;
        }
      }
      // 钾 sK
      const sKIndex = this.deviceDataList.findIndex(
        (item) => item.alias === "sK"
      );
      if (sKIndex != -1) {
        const sKLast_data = this.deviceDataList[sKIndex]?.last_data;
        if (sKLast_data > this.info.new_img.sKNum) {
          this.deviceDataList[sKIndex]["isResult"] = 2;
        } else if (sKLast_data < this.info.new_img.sKNum) {
          this.deviceDataList[sKIndex]["isResult"] = 3;
        } else {
          this.deviceDataList[sKIndex]["isResult"] = 1;
        }
      }

      // 假的
      // this.deviceDataList = [
      //   {
      //     alias: "soilT",
      //     data_unit: "℃",
      //     icon: "soilT",
      //     id: 657,
      //     intro: "土壤温度1",
      //     last_data: 28.77,
      //     number: "8026",
      //     photo: "",
      //     position: "",
      //     title: "土壤温度1",
      //     type: 0,
      //     weight: 1,
      //     isResult: 2,
      //   },
      //   {
      //     alias: "sN",
      //     data_unit: "mg/kg",
      //     icon: "sN",
      //     id: 6571,
      //     intro: "氮",
      //     last_data: 23.9,
      //     number: "8026",
      //     // 氮
      //     sNNum: 23.9,
      //     // 磷
      //     sPNum: 2.25,
      //     // 钾
      //     sKNum: 8.2,
      //     photo: "",
      //     position: "",
      //     title: "氮",
      //     type: 0,
      //     weight: 1,
      //     isResult: 1,
      //   },
      //   {
      //     alias: "sP",
      //     data_unit: "mg/kg",
      //     icon: "sP",
      //     id: 6572,
      //     intro: "磷",
      //     last_data: 2.24,
      //     number: "8026",
      //     // 氮
      //     sNNum: 23.9,
      //     // 磷
      //     sPNum: 2.25,
      //     // 钾
      //     sKNum: 8.2,
      //     photo: "",
      //     position: "",
      //     title: "磷",
      //     type: 0,
      //     weight: 1,
      //     isResult: 3,
      //   },
      //   {
      //     alias: "sK",
      //     data_unit: "mg/kg",
      //     icon: "sK",
      //     id: 6573,
      //     intro: "钾",
      //     last_data: 8.2,
      //     number: "8026",
      //     // 氮
      //     sNNum: 23.9,
      //     // 磷
      //     sPNum: 2.25,
      //     // 钾
      //     sKNum: 8.2,
      //     photo: "",
      //     position: "",
      //     title: "钾",
      //     type: 0,
      //     weight: 1,
      //     isResult: 1,
      //   },
      // ];
      // 假
    },
    // 设备节点列表
    async getEquipmentChildList(table_id = this.table_id) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
        page_size: 20,
      });
      if (code === 0 && results) {
        results.forEach((item) => {
          this.getEquipmentInfoApi_status(table_id, item.icon);
          switch (item.icon) {
            case "soilH":
              // 湿度
              this.changeDate(item.id, item.icon);
              break;
            case "soilT":
              // 温度
              this.changeDate(item.id, item.icon);
              break;
            case "soilC":
              // 电导率
              this.changeDate(item.id, item.icon);
              break;
            case "soilS":
              // 盐分
              this.changeDate(item.id, item.icon);
              break;
            case "dPH":
              this.changeDate(item.id, item.icon);
              break;
            case "sN":
              // 土壤氮传感器
              this.changeDate(item.id, item.icon);
              break;
            case "sP":
              // 磷传感器
              this.changeDate(item.id, item.icon);
              break;
            case "sK":
              this.changeDate(item.id, item.icon);
              break;
            case "vbat":
              // 电池电压
              this.changeDate(item.id, item.icon);
              break;
            default:
              break;
          }
        });

        this.deviceDataList = results;
        this.$forceUpdate();
      }
    },
    async changeDate(child_id, icon) {
      let day = moment(new Date()).format("YYYY-MM-DD");
      const { results } = await getEquipmentChildListInfoApi({
        manor_id: this.farmID,
        table_id: this.table_id,
        child_id,
        day,
        end_day: day,
        act: "history",
      });
      switch (icon) {
        case "soilH":
          this.initBarEchart(results.data);
          break;
        case "soilT":
          this.initTemperatureEchart(results?.data);
          break;
        case "soilC":
          this.initConductivityEchart(results?.data);
          break;
        case "soilS":
          this.initSoilSCharts(results?.data);
          break;
        case "vbat":
          this.initVoltageEchart(results?.data);
          break;
        case "dPH":
          this.initPhEchart(results?.data);
          break;
        case "sN":
          this.initAmmoniaEchart(results?.data);
          break;
        case "sP":
          this.initPhosphorusEchart(results?.data);
          break;
        case "sK":
          this.initPotassiumEchart(results?.data);

          break;

        default:
          break;
      }
    },
    // 设备详情 icon
    async getEquipmentInfoApi_status(table_id, icon) {
      // let info = {};
      const { results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id,
        act: "status",
      });
      if (icon === "soilH") {
        const keyIndex = this.deviceDataList.findIndex((item) => {
          return item.alias === "soilH";
        });
        let data1 = this.deviceDataList[keyIndex]?.number;
        for (let keys in results.data) {
          if (results.data[keys].number == data1) {
            this.soilHOnline = results.data[keys].online || 0;
            this.deviceDataList[keyIndex].last_data = Number(
              results.data[keys].last_data
            );
          }
        }
      } else if (icon === "soilT") {
        const keyIndex = this.deviceDataList.findIndex((item) => {
          return item.alias === "soilT";
        });
        let data1 = this.deviceDataList[keyIndex]?.number;
        for (let keys in results.data) {
          if (results.data[keys].number == data1) {
            this.soilTOnline = results.data[keys].online || 0;

            this.deviceDataList[keyIndex].last_data = Number(
              results.data[keys].last_data
            );
          }
        }
      } else if (icon === "soilC") {
        const keyIndex = this.deviceDataList.findIndex((item) => {
          return item.alias === "soilC";
        });
        let data1 = this.deviceDataList[keyIndex]?.number;
        for (let keys in results.data) {
          if (results.data[keys].number == data1) {
            this.soilCOnline = results.data[keys].online || 0;

            this.deviceDataList[keyIndex].last_data = Number(
              results.data[keys].last_data
            );
          }
        }
      } else if (icon === "soilS") {
        const keyIndex = this.deviceDataList.findIndex((item) => {
          return item.alias === "soilS";
        });
        let data1 = this.deviceDataList[keyIndex]?.number;
        for (let keys in results.data) {
          if (results.data[keys].number == data1) {
            this.soilSOnline = results.data[keys].online || 0;

            this.deviceDataList[keyIndex].last_data = Number(
              results.data[keys].last_data
            );
          }
        }
      } else if (icon === "dPH") {
        const keyIndex = this.deviceDataList.findIndex((item) => {
          return item.alias === "dPH";
        });
        let data1 = this.deviceDataList[keyIndex]?.number;
        for (let keys in results.data) {
          if (results.data[keys].number == data1) {
            this.dPHOnline = results.data[keys].online || 0;

            this.deviceDataList[keyIndex].last_data = Number(
              results.data[keys].last_data
            );
          }
        }
      } else if (icon === "sN") {
        const keyIndex = this.deviceDataList.findIndex((item) => {
          return item.alias === "sN";
        });
        let data1 = this.deviceDataList[keyIndex]?.number;
        for (let keys in results.data) {
          if (results.data[keys].number == data1) {
            this.sNOnline = results.data[keys].online || 0;

            this.deviceDataList[keyIndex].last_data = Number(
              results.data[keys].last_data
            );
          }
        }
      } else if (icon === "sP") {
        const keyIndex = this.deviceDataList.findIndex((item) => {
          return item.alias === "sP";
        });
        let data1 = this.deviceDataList[keyIndex]?.number;
        for (let keys in results.data) {
          if (results.data[keys].number == data1) {
            this.sPOnline = results.data[keys].online || 0;

            this.deviceDataList[keyIndex].last_data = Number(
              results.data[keys].last_data
            );
          }
        }
      } else if (icon === "sK") {
        const keyIndex = this.deviceDataList.findIndex((item) => {
          return item.alias === "sK";
        });
        let data1 = this.deviceDataList[keyIndex]?.number;
        for (let keys in results.data) {
          if (results.data[keys].number == data1) {
            this.sKOnline = results.data[keys].online || 0;

            this.deviceDataList[keyIndex].last_data = Number(
              results.data[keys].last_data
            );
          }
        }
      } else if (icon === "vbat") {
        const keyIndex = this.deviceDataList.findIndex((item) => {
          return item.alias === "vbat";
        });
        let data1 = this.deviceDataList[keyIndex]?.number;
        for (let keys in results.data) {
          if (results.data[keys].number == data1) {
            this.vbatOnline = results.data[keys].online || 0;
            this.deviceDataList[keyIndex].last_data = Number(
              results.data[keys].last_data
            );
          }
        }
      }
    },
    // 眼
    handleCycle() {
      const date = new Date();
      const month = date.getMonth() + 1;
      if (month > 9) {
        this.info.new_img = {
          img: "chengshuqi",
          title: "成熟期",
          date: [10],
          minNum: 8,
          maxNum: 20,
          // 氮
          sNNum: 23.9,
          // 磷
          sPNum: 2.25,
          // 钾
          sKNum: 8.2,
        };
        this.info.counts = [];
        return;
      }
      this.cycleList.forEach((item) => {
        if (item.date.includes(month)) {
          this.info.new_img = {
            img: item.img,
            title: item.title,
            date: item.date,
            minNum: item.minNum,
            maxNum: item.maxNum,
            sNNum: item.sNNum,
            sPNum: item.sPNum,
            sKNum: item.sKNum,
          };
        } else {
          this.info.counts.push({
            img: item.img,
            title: item.title,
            date: item.date,
            minNum: item.minNum,
            maxNum: item.maxNum,
            sNNum: item.sNNum,
            sPNum: item.sPNum,
            sKNum: item.sKNum,
          });
        }
      });
    },
    initPhosphorusEchart(results) {
      this.phosphorusShow = false;
      let option_bar = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,84,79,.8)",
          borderColor: "rgba(255,84,79,.8)",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 磷含量：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "18%",
          bottom: "10%",
          right: "5%",
        },
        xAxis: {
          data: [],
          axisLabel: {
            inside: true,
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#999",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(45, 60, 92, 0.3)",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            showBackground: true,
            barMaxWidth: 14,
            itemStyle: {
              borderRadius: [14, 14, 0, 0],
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#FAD126" },
                { offset: 1, color: "#FF544F" },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#FAD126" },
                  { offset: 1, color: "#FF544F" },
                ]),
              },
            },
            data: [],
          },
        ],
      };
      option_bar["xAxis"]["data"] = [];
      option_bar["series"][0]["data"] = [];
      results.forEach((a) => {
        let date = new Date(a["x"]);
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let xAxis = h + ":" + date.getMinutes();
        option_bar["xAxis"]["data"].push(xAxis);
        option_bar["series"][0]["data"].push(a["y"]);
      });
      option_bar["yAxis"]["name"] = "单位：mg/kg";
      this.chartsPhosphorus.setOption(option_bar);
    },
    initPotassiumEchart(results) {
      this.potassiumShow = false;
      let option_bar = {
        grid: {
          top: "18%",
          bottom: "14%",
          right: "5%",
          left: "15%",
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          interval: 55,
        },
        yAxis: {
          name: "单位",
          nameLocation: "end",
          nameGap: 14,
          nameTextStyle: {
            fontSize: 12,
            align: "right",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(45, 60, 92, 0.3)",
            },
          },
          type: "value",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#3BEDDE",
          borderColor: "#3BEDDE",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 钾含量：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "line",
            data: [1, 54, 77, 24, 15, 87, 38],
            showSymbol: false,
            lineStyle: {
              color: "rgb(59,237,222)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#3BEDDE",
              color: "#fff",
            },
            areaStyle: {
              color: {
                type: "linearGradient",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(59,237,222,.28)",
                  },
                  {
                    offset: 1,
                    color: "rgba(59,237,222,0)",
                  },
                ],
              },
            },
          },
        ],
      };
      option_bar["xAxis"]["data"] = [];
      option_bar["series"][0]["data"] = [];
      results.forEach((a) => {
        let date = new Date(a["x"]);
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let xAxis = h + ":" + date.getMinutes();
        option_bar["xAxis"]["data"].push(xAxis);
        option_bar["series"][0]["data"].push(a["y"]);
      });
      option_bar["yAxis"]["name"] = "单位：mg/kg";
      this.chartsPotassium.setOption(option_bar);
    },
    initAmmoniaEchart(results) {
      this.ammoniaShow = false;
      let option_bar = {
        grid: {
          top: "18%",
          bottom: "14%",
          right: "5%",
          left: "16%",
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          interval: 55,
        },
        yAxis: {
          name: "单位",
          nameLocation: "end",
          nameGap: 14,
          nameTextStyle: {
            fontSize: 12,
            align: "right",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(45, 60, 92, 0.3)",
            },
          },
          type: "value",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#32B7E9",
          borderColor: "#32B7E9",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 氮含量：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "line",
            data: [],
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "rgb(50, 183, 233)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#32B7E9",
              color: "#fff",
            },
            areaStyle: {
              color: {
                type: "linearGradient",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(50, 183, 233,.28)",
                  },
                  {
                    offset: 1,
                    color: "rgba(50, 183, 233,0)",
                  },
                ],
              },
            },
          },
        ],
      };
      option_bar["xAxis"]["data"] = [];
      option_bar["series"][0]["data"] = [];
      results.forEach((a) => {
        let date = new Date(a["x"]);
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let xAxis = h + ":" + date.getMinutes();
        option_bar["xAxis"]["data"].push(xAxis);
        option_bar["series"][0]["data"].push(a["y"]);
      });
      option_bar["yAxis"]["name"] = "单位：mg/kg";
      this.chartsAmmonia.setOption(option_bar);
    },
    initVoltageEchart(results) {
      this.voltageShow = false;
      let option_bar = {
        grid: {
          top: "18%",
          bottom: "14%",
          right: "5%",
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          interval: 55,
        },
        yAxis: {
          name: "单位",
          nameLocation: "end",
          nameGap: 14,
          nameTextStyle: {
            fontSize: 12,
            align: "right",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(45, 60, 92, 0.3)",
            },
          },
          type: "value",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#32B7E9",
          borderColor: "#32B7E9",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 电池电压：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "line",
            data: [1, 54, 77, 24, 15, 87, 38],
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "rgb(50, 183, 233)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#32B7E9",
              color: "#fff",
            },
            areaStyle: {
              color: {
                type: "linearGradient",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(50, 183, 233,.28)",
                  },
                  {
                    offset: 1,
                    color: "rgba(50, 183, 233,0)",
                  },
                ],
              },
            },
          },
        ],
      };
      option_bar["xAxis"]["data"] = [];
      option_bar["series"][0]["data"] = [];
      results.forEach((a) => {
        let date = new Date(a["x"]);
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let xAxis = h + ":" + date.getMinutes();
        option_bar["xAxis"]["data"].push(xAxis);
        option_bar["series"][0]["data"].push(a["y"]);
      });
      option_bar["yAxis"]["name"] = "单位：V";
      this.chartsVoltage.setOption(option_bar);
    },
    initConductivityEchart(results) {
      this.conductivityShow = false;
      let option_bar = {
        grid: {
          top: "18%",
          bottom: "14%",
          right: "5%",
          left: "15%",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(170,255,0, 0.8)",
          borderColor: "rgba(170,255,0, 0.8)",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 电导率：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          interval: 55,
        },
        yAxis: {
          name: "单位",
          nameLocation: "end",
          nameGap: 14,
          nameTextStyle: {
            fontSize: 12,
            align: "right",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(45, 60, 92, 0.3)",
            },
          },
          type: "value",
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "line",
            data: [],
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "rgb(170,255,0)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#aaff00",
              color: "#fff",
            },
            areaStyle: {
              color: {
                type: "linearGradient",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(170,255,0,.28)",
                  },
                  {
                    offset: 1,
                    color: "rgba(170,255,0,0)",
                  },
                ],
              },
            },
          },
        ],
      };
      option_bar["xAxis"]["data"] = [];
      option_bar["series"][0]["data"] = [];
      results.forEach((a) => {
        let date = new Date(a["x"]);
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let xAxis = h + ":" + date.getMinutes();
        option_bar["xAxis"]["data"].push(xAxis);
        option_bar["series"][0]["data"].push(a["y"]);
      });
      option_bar["yAxis"]["name"] = "单位：uS/cm";
      this.chartsConductivity.setOption(option_bar);
    },
    initSoilSCharts(results) {
      this.SoilSShow = false;
      let option_bar = {
        grid: {
          top: "18%",
          bottom: "14%",
          right: "5%",
          left: "15%",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(157,144,135, 0.8)",
          borderColor: "rgba(157,144,135, 0.8)",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 含盐量：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          interval: 55,
        },
        yAxis: {
          name: "单位",
          nameLocation: "end",
          nameGap: 14,
          nameTextStyle: {
            fontSize: 12,
            align: "right",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(45, 60, 92, 0.3)",
            },
          },
          type: "value",
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "line",
            data: [],
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "rgb(157,144,135)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#9d9087",
              color: "#fff",
            },
            areaStyle: {
              color: {
                type: "linearGradient",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(157,144,135,.28)",
                  },
                  {
                    offset: 1,
                    color: "rgba(170,255,0,0)",
                  },
                ],
              },
            },
          },
        ],
      };
      option_bar["xAxis"]["data"] = [];
      option_bar["series"][0]["data"] = [];
      results.forEach((a) => {
        let date = new Date(a["x"]);
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let xAxis = h + ":" + date.getMinutes();
        option_bar["xAxis"]["data"].push(xAxis);
        option_bar["series"][0]["data"].push(a["y"]);
      });
      option_bar["yAxis"]["name"] = "单位：ppm";
      this.chartsSoilS.setOption(option_bar);
    },
    initPhEchart(results) {
      this.phShow = false;
      let option_bar = {
        grid: {
          top: "14%",
          bottom: "14%",
          right: "5%",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "rgba(45, 60, 92, 0.3)",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            data: [],
            barMaxWidth: 20,
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
            },
          },
        ],
      };
      option_bar["xAxis"]["data"] = [];
      option_bar["series"][0]["data"] = [];
      results.forEach((a) => {
        let date = new Date(a["x"]);
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let xAxis = h + ":" + date.getMinutes();
        option_bar["xAxis"]["data"].push(xAxis);
        option_bar["series"][0]["data"].push(a["y"]);
      });
      this.chartsPh.setOption(option_bar);
    },
    initTemperatureEchart(results) {
      this.temperatureShow = false;
      let option_bar = {
        grid: {
          top: "18%",
          bottom: "14%",
          right: "5%",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(91,105,190, 0.8)",
          borderColor: "rgba(91,105,190, 0.8)",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 土壤温度：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            color: "#fff",
            interval: "auto",
            fontSize: 12,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#fff",
            fontSize: 12,
          },
          splitLine: {
            lineStyle: {
              color: "rgba(45, 60, 92, 0.3)",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            name: "A",
            data: [],
            type: "bar",
            // barWidth: 10,
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#009CFF" },
                { offset: 0.5, color: "#5B69BE" },
                { offset: 1, color: "#A478FF" },
              ]),
            },
          },
          {
            name: "B",
            type: "bar",
            // barWidth: 14,
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 0, color: "#009CFF" },
                { offset: 0.5, color: "#5B69BE" },
                { offset: 1, color: "#A478FF" },
              ]),
            },
            barGap: "-100%",
            data: [],
          },
          {
            //顶部
            tooltip: {
              show: false,
            },
            type: "pictorialBar",
            itemStyle: {
              // borderWidth: 1,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "#43D1FF" },
                  { offset: 1, color: "#B370FF" },
                ],
              },
            },
            symbol: "circle",
            // symbolSize: ["14", "10"],
            symbolSize: ["100%", "10"],
            symbolOffset: ["0", "-3"],
            symbolPosition: "end",
            data: [],
            z: 3,
          },
        ],
      };
      option_bar["xAxis"]["data"] = [];
      option_bar["series"][0]["data"] = [];
      results.forEach((a) => {
        let date = new Date(a["x"]);
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let xAxis = h + ":" + date.getMinutes();
        option_bar["xAxis"]["data"].push(xAxis);
        option_bar["series"][0]["data"].push(a["y"]);
        option_bar["series"][1]["data"].push(a["y"]);
        option_bar["series"][2]["data"].push(a["y"]);
      });
      option_bar["yAxis"]["name"] = "单位：℃";
      this.chartsTemperature.setOption(option_bar);
    },

    initBarEchart(results) {
      this.humidityShow = false;
      let option_bar = {
        dataZoom: [
          {
            type: "inside",
          },
        ],
        grid: {
          top: "18%",
          bottom: "14%",
          right: "8%",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(25,217,170, 0.8)",
          borderColor: "rgba(25,217,170, 0.8)",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 土壤湿度：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              color: "rgba(45, 60, 92, 0.3)",
            },
          },
          type: "value",
          axisLabel: {
            formatter: "{value} °C",
          },
        },
        series: [
          {
            itemStyle: {
              borderWidth: 2,
              borderColor: "#9d9087",
              color: "#fff",
            },
            lineStyle: {
              color: "#19D9AA",
              width: 2,
            },
            // name: "Lowest",
            type: "line",
            data: [],
            markPoint: {
              // data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
            },
            markLine: {
              data: [
                { type: "average", name: "Avg" },
                // [
                //   {
                //     symbol: "none",
                //     x: "90%",
                //     yAxis: "max",
                //   },
                //   {
                //     symbol: "circle",
                //     label: {
                //       position: "start",
                //       formatter: "Max",
                //     },
                //     type: "max",
                //     name: "最高点",
                //   },
                // ],
              ],
            },
          },
        ],
      };
      option_bar["xAxis"]["data"] = [];
      option_bar["series"][0]["data"] = [];
      results.forEach((a) => {
        let date = new Date(a["x"]);
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let xAxis = h + ":" + date.getMinutes();
        option_bar["xAxis"]["data"].push(xAxis);
        option_bar["series"][0]["data"].push(a["y"]);
      });

      this.chartsHumidity.setOption(option_bar);
    },

    initCharts() {
      this.option = this.getPie3D(this.optionData, 0);
      this.chartsSalinity.setOption(this.option);
      this.bindListen(this.chartsSalinity);
    },

    // 盐分开始
    bindListen(myChart) {
      let that = this;
      let selectedIndex = "";
      let hoveredIndex = "";
      myChart.on("click", function (params) {
        let isSelected =
          !that.option.series[params.seriesIndex].pieStatus.selected;
        let isHovered =
          that.option.series[params.seriesIndex].pieStatus.hovered;
        let k = that.option.series[params.seriesIndex].pieStatus.k;
        let startRatio =
          that.option.series[params.seriesIndex].pieData.startRatio;
        let endRatio = that.option.series[params.seriesIndex].pieData.endRatio;
        if (selectedIndex !== "" && selectedIndex !== params.seriesIndex) {
          that.option.series[selectedIndex].parametricEquation =
            that.getParametricEquation(
              that.option.series[selectedIndex].pieData.startRatio,
              that.option.series[selectedIndex].pieData.endRatio,
              false,
              false,
              k,
              that.option.series[selectedIndex].pieData.value
            );
          that.option.series[selectedIndex].pieStatus.selected = false;
        }
        that.option.series[params.seriesIndex].parametricEquation =
          that.getParametricEquation(
            startRatio,
            endRatio,
            isSelected,
            isHovered,
            k,
            that.option.series[params.seriesIndex].pieData.value
          );
        that.option.series[params.seriesIndex].pieStatus.selected = isSelected;
        isSelected ? (selectedIndex = params.seriesIndex) : null;
        myChart.setOption(that.option);
      });
      myChart.on("mouseover", function (params) {
        let isSelected;
        let isHovered;
        let startRatio;
        let endRatio;
        let k;
        if (hoveredIndex === params.seriesIndex) {
          return;
        } else {
          if (hoveredIndex !== "") {
            isSelected = that.option.series[hoveredIndex].pieStatus.selected;
            isHovered = false;
            startRatio = that.option.series[hoveredIndex].pieData.startRatio;
            endRatio = that.option.series[hoveredIndex].pieData.endRatio;
            k = that.option.series[hoveredIndex].pieStatus.k;
            that.option.series[hoveredIndex].parametricEquation =
              that.getParametricEquation(
                startRatio,
                endRatio,
                isSelected,
                isHovered,
                k,
                that.option.series[hoveredIndex].pieData.value
              );
            that.option.series[hoveredIndex].pieStatus.hovered = isHovered;
            hoveredIndex = "";
          }
          if (
            params.seriesName !== "mouseoutSeries" &&
            params.seriesName !== "pie2d"
          ) {
            isSelected =
              that.option.series[params.seriesIndex].pieStatus.selected;
            isHovered = true;
            startRatio =
              that.option.series[params.seriesIndex].pieData.startRatio;
            endRatio = that.option.series[params.seriesIndex].pieData.endRatio;
            k = that.option.series[params.seriesIndex].pieStatus.k;
            that.option.series[params.seriesIndex].parametricEquation =
              that.getParametricEquation(
                startRatio,
                endRatio,
                isSelected,
                isHovered,
                k,
                that.option.series[params.seriesIndex].pieData.value + 5
              );
            that.option.series[params.seriesIndex].pieStatus.hovered =
              isHovered;
            hoveredIndex = params.seriesIndex;
          }
          myChart.setOption(that.option);
        }
      });
      myChart.on("globalout", function () {
        let isSelected;
        let isHovered;
        let startRatio;
        let endRatio;
        let k;
        if (hoveredIndex !== "") {
          isSelected = that.option.series[hoveredIndex].pieStatus.selected;
          isHovered = false;
          k = that.option.series[hoveredIndex].pieStatus.k;
          startRatio = that.option.series[hoveredIndex].pieData.startRatio;
          endRatio = that.option.series[hoveredIndex].pieData.endRatio;
          that.option.series[hoveredIndex].parametricEquation =
            that.getParametricEquation(
              startRatio,
              endRatio,
              isSelected,
              isHovered,
              k,
              that.option.series[hoveredIndex].pieData.value
            );
          that.option.series[hoveredIndex].pieStatus.hovered = isHovered;
          hoveredIndex = "";
        }
        myChart.setOption(that.option);
      });
    },
    getPie3D(pieData, internalDiameterRatio) {
      let that = this;
      let series = [];
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      let legendData = [];
      let legendBfb = [];
      let k = 1 - internalDiameterRatio;
      pieData.sort((a, b) => {
        return b.value - a.value;
      });
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;
        let seriesItem = {
          name:
            typeof pieData[i].name === "undefined"
              ? `series${i}`
              : pieData[i].name,
          type: "surface",
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k,
          },
          center: ["10%", "50%"],
        };
        if (typeof pieData[i].itemStyle != "undefined") {
          let itemStyle = {};
          typeof pieData[i].itemStyle.color != "undefined"
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null;
          typeof pieData[i].itemStyle.opacity != "undefined"
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null;
          seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
      }
      legendData = [];
      legendBfb = [];
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value;
        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          series[i].pieData.value
        );
        startValue = endValue;
        let bfb = that.fomatFloat(series[i].pieData.value / sumValue, 4);
        legendData.push({
          name: series[i].name,
          value: bfb,
        });
        legendBfb.push({
          name: series[i].name,
          value: bfb,
        });
      }
      let boxHeight = this.getHeight3D(series, 15);
      //  传入 legendData、 series
      let option = {
        tooltip: {
          formatter: (params) => {
            if (
              params.seriesName !== "mouseoutSeries" &&
              params.seriesName !== "pie2d"
            ) {
              let bfb = (
                (option.series[params.seriesIndex].pieData.endRatio -
                  option.series[params.seriesIndex].pieData.startRatio) *
                100
              ).toFixed(2);
              return (
                `${params.seriesName}<br/>` +
                `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
                `${bfb}`
              );
            }
          },
        },
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },

        grid3D: {
          show: false,
          boxHeight,
          top: "center",
          left: "center",
          width: "60%",
          height: "60%",
          viewControl: {
            alpha: 20, //角度
            distance: 180, //视角到主体的距离
            rotateSensitivity: 1, //旋转
            zoomSensitivity: 1, //缩放
            panSensitivity: 0, //平移
            autoRotate: true,
          },
        },
        series: series,
      };
      return option;
    },
    getHeight3D(series, height) {
      series.sort((a, b) => {
        return b.pieData.value - a.pieData.value;
      });
      return (height * 25) / series[0].pieData.value;
    },
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
      let midRatio = (startRatio + endRatio) / 2;
      let startRadian = startRatio * Math.PI * 2;
      let endRadian = endRatio * Math.PI * 2;
      let midRadian = midRatio * Math.PI * 2;
      if (startRatio === 0 && endRatio === 1) {
        isSelected = false;
      }
      k = typeof k !== "undefined" ? k : 1 / 3;
      let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
      let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
      let hoverRate = isHovered ? 1.05 : 1;
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },
        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },
        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u) * h * 0.1;
          }
          return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
        },
      };
    },
    fomatFloat(num, n) {
      var f = parseFloat(num);
      if (isNaN(f)) {
        return false;
      }
      f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n);
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + n) {
        s += "0";
      }
      return s;
    },
    async getAllMarkDataApi() {
      const { code, results } = await getAllMarkDataApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.cameraList = results;

        results.forEach((item) => {
          if (item.group !== "地块") {
            this.allDataList[item.group] = [];
            item.items.forEach((item2) => {
              let obj = {
                position: "",
                title: "",
                img: "",
              };
              let iconType = "";
              if (item2.icon === "video") {
                iconType = "video";
              } else if (item2.icon === "weather") {
                iconType = "weather";
              } else {
                iconType = "water";
              }

              obj.img = `${this.$imgOnlineUrl}/index/${iconType}${item2.online}.png`;
              obj.title = item.group;
              obj.id = item2.id;
              obj.online = item2.online;
              obj.iconType = iconType;
              obj.icon = item2.icon;
              obj.position = [
                +item2.mark_data.split(",")[0],
                +item2.mark_data.split(",")[1],
              ];
              this.allDataList[item.group].push(obj);
            });
          }
        });
      }
    },

    allFunction() {
      this.getEquipmentChildList();
    },
    // //30分钟更新一次
    updatePage() {
      this.setInterval2 = setInterval(() => {
        this.allFunction();
      }, 1800000);
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes animtran {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@keyframes animtrans {
  from {
    transform: rotateZ(360deg);
  }

  to {
    transform: rotateZ(0deg);
  }
}
@keyframes alwaysRotate {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  100% {
    transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
  }
}

@-webkit-keyframes animtran {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes animtrans {
  from {
    transform: rotateZ(360deg);
  }

  to {
    transform: rotateZ(0deg);
  }
}
@keyframes jumpBoxHandler {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 8px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes rotation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.soilScreen {
  width: 100vw;
  height: 100vh;
  background: url(../../assets/image/analyse/bg.png) no-repeat #0b195c;
  background-size: cover;
  .header {
    width: 100%;
    height: 113px;
    background: url(../../assets/image/analyse/head_bg.png) center center
      no-repeat;
    background-size: cover;
    line-height: 113px;
    text-align: center;
    position: relative;
    .logo-img {
      width: 82px;
      position: absolute;
      top: 7%;
      left: 41px;
    }
    .header-title {
      font-family: Adobe Heiti Std;
      font-weight: normal;
      font-size: 36px;
      letter-spacing: 2px;

      background: linear-gradient(
        180deg,
        #eaeffb 0%,
        #cae7fc 51%,
        #abd8fa 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .content-box {
    height: calc(100% - 113px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    .right-box,
    .conter-box,
    .left-box {
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: #3e90e5;
      }
      .charts-box {
        width: 545px;
        height: 220px;
        background: transparent;
        border: 1px solid #3694fc;
        box-shadow: 0 0 4px 1px #3694fc;
        margin-bottom: 10px;
        position: relative;
        .charts-title {
          width: 100%;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 33px;

          .warning {
            width: 37px;
          }
          .title-box {
            width: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &::after {
              content: "";
              width: 100%;
              height: 1px;
              background: linear-gradient(to right, #131962, #348ef4, #131962);
              position: absolute;
              bottom: -13px;
              left: 50%;
              transform: translateX(-50%);
            }
            .title_icon {
              width: 49px;
              height: 24px;
              &:nth-of-type(2) {
                transform: rotate(180deg);
              }
            }
            span {
              font-family: Adobe Heiti Std;
              font-weight: normal;
              font-size: 20px;
              color: #fff;
              margin: 0 58px;
            }
          }
          .switch-box {
            width: 37px;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
            .switch {
              width: 100%;
            }
          }
        }
        .charts-data-box {
          width: 100%;
          height: calc(100% - 56px);
          &.humidity {
          }
        }
        .empty-box {
          width: 100%;
          height: 100%;
          font-size: 16px;
          color: #ccc;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9999;
        }
      }
    }

    .conter-box {
      width: 810px;
      // flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .cycle-box {
        width: 100%;
        height: calc(100% - 220px);

        #advert {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          position: relative;
          overflow: hidden;
          .ym-particles {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 8;
          }
          .bg_img {
            width: 100%;
            height: 100%;
            background: url(../../assets/image/analyse/cycle_bg0.png) center
              center no-repeat;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;
            // animation: rotateBg 2s infinite;
            animation: alwaysRotate 3s ease infinite alternate;
            -webkit-animation: alwaysRotate 3s ease infinite alternate;
          }
          .bg1 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            background: url(../../assets/image/analyse/cycle_bg1.png) center
              center no-repeat;
            background-size: cover;
            .out-box {
              width: 100%;
              height: 100%;
              position: relative;
              background: url(../../assets/image/analyse/cycle_bg2.png) center
                center no-repeat;
              background-size: 100% 100%;
              &.bg1_view {
                animation-name: animtrans;
                animation-duration: 8s;
                animation-fill-mode: forwards;
                animation-direction: reverse;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.8, 0.04, 0.02, 0.08);
              }
              &.active {
                img {
                  animation-name: animtrans;
                  animation-duration: 8s;
                  animation-fill-mode: forwards;
                  animation-direction: reverse;
                  animation-iteration-count: infinite;
                  animation-timing-function: cubic-bezier(
                    0.8,
                    0.04,
                    0.02,
                    0.08
                  );
                }
              }
              img {
                margin-bottom: 10px;
                &.icon0fenhuaqi {
                  width: 110px;
                  // height: 668px;
                }
                &.icon0kaihuaqi {
                  width: 110px;
                  // height: 171px;
                }
                &.icon0miaoqi {
                  width: 110px;
                  // height: 203px;
                }

                &.icon0guanjiangqi {
                  width: 110px;
                  // height: 185px;
                }
                &.icon0fennieqi {
                  width: 110px;
                  // height: 186px;
                }
                &.icon0chengshuqi {
                  width: 300px;
                  // height: 276px;
                }
              }
              .cycle-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 14px;
                position: absolute;

                &.fenhuaqi {
                  animation: jumpBoxHandler 3s infinite;
                  animation-delay: 0;
                  // top: 14px;
                  // left: 20px;
                }
                &.kaihuaqi {
                  animation: jumpBoxHandler 3s infinite;
                  animation-delay: 0.2s;
                  // bottom: 14px;
                  // left: 20px;
                }
                &.miaoqi {
                  animation: jumpBoxHandler 3s infinite;
                  animation-delay: 0.3s;
                  // bottom: 14px;
                  // right: 20px;
                }
                &.guanjiangqi {
                  animation: jumpBoxHandler 3s infinite;
                  animation-delay: 0.4s;
                  // top: 14px;
                  // right: 20px;
                }
                &.fennieqi {
                  animation: jumpBoxHandler 3s infinite;
                  animation-delay: 0.5s;
                  // top: 8px;
                  // right: 20px;
                }
                &:nth-of-type(1) {
                  top: 14px;
                  left: 20px;
                }
                &:nth-of-type(2) {
                  bottom: 14px;
                  left: 20px;
                }
                &:nth-of-type(3) {
                  bottom: 14px;
                  right: 20px;
                }
                &:nth-of-type(4) {
                  top: 14px;
                  right: 20px;
                }
              }
            }
          }
          .content {
            position: absolute;
            z-index: 11;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(1.7);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &.content_active {
              -webkit-animation: rotation 2s linear infinite;
              animation: rotation 2s linear infinite;
            }
            img {
              // width: 50%;
              width: 35%;

              cursor: pointer;
            }
            span {
              color: #fff;
              font-size: 14px;
              margin-bottom: 10px;
            }
          }
        }
      }
      .charts-box {
        width: 100%;
      }
    }
  }
}
</style>
